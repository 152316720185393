.FeaturesSection2 {
  &__item {
    padding: 56px;
  }

  &__image-container {
    margin: 0 auto;
    max-width: 200px;
    margin-bottom: 30px;
  }
}
