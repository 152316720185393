.FeaturesSection4 {
  &__image-container {
    max-width: 570px;
  }

  &__icon {
    font-size: 70px;
    width: 70px;
    height: 70px;
  }
}
