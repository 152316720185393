.AuthFooter {
  font-size: 0.9rem;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
